<template>

    <!--    SIDEBAR MENU -->
    <div class="sidebar">
        <div
            v-if="isPanelOpen"
            class="sidebar-backdrop"
            @click="closeSidebarPanel()">
        </div>
        <transition name="slide">
            <div v-if="isPanelOpen"
                 class="sidebar-panel">
                <ul class="sidebar-panel-nav">
                    <li v-for="(item, index) of options.itemsMenu"
                        :key="index"
                        class="aside-menu__item aside-menu_item_burger"
                        @click="[toRoute(item.url, item.slide), closeSidebarPanel()]">
                        <span v-html="$tm(`text.${item.title}`)"></span>
                    </li>
                </ul>
            </div>
        </transition>
    </div>

    <div class="aside-menu"
         :class="{ 'aside-menu_blue': options.color === 'blue',
                   'aside-menu_blue-dark': options.color === 'blue-dark',
                   'aside-menu_right-side': options.position === 'right-side',}">

        <!-- header-->
        <div class="aside-menu__header-items">

            <div class="aside-menu__header-item"
                         v-for="(item, index) of options.itemsHeader"
                         :key="index">
                <img
                    class="cursor-pointer"
                    @click="openSidebarPanel()"
                    :src="require(`@/assets/images/aside/${item.image}`)" alt=""/>
            </div>
        </div>

        <!-- menu-->
        <div class="aside-menu__container">

            <div class="aside-menu__items aside-menu_items_rotate">

                <div v-for="(item, index) of options.itemsMenu"
                     :key="index"
                     class="aside-menu__item"
                     @click="toRoute(item.url, item.slide)">
                    <span v-html="$tm(`text.${item.title}`)"></span>
                </div>
            </div>

            <!-- footer-->
            <div class="aside-menu__footer-items">

                <router-link class="aside-menu__footer-item"
                             :to="item.url"
                             v-for="(item, index) of options.itemsFooter"
                             :key="index">
                    <div @click="item.slide? toSlide(item.slide) : openNewTab(item.url)">
                        <img :src="require(`@/assets/images/aside/${item.image}`)" alt=""/>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  name: 'aside-menu',

  props: {
    options: Object,
  },

  methods: {
    openNewTab(path) {
      window.open(path, '_blank');
    },

    toRoute(url, slide) {
      if (slide) {
        this.$router.push({ path: url, query: { slide } });
      } else {
        this.$router.push({ path: url });
      }
    },

    closeSidebarPanel() {
      this.isPanelOpen = false;
    },

    openSidebarPanel() {
      this.isPanelOpen = true;
    },
  },

  data() {
    return {
      menuStatus: false,
      isPanelOpen: false,
    };
  },

};
</script>
