import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './app.vue';
import router from './router';
import { ENGLISH_TRANSLATIONS } from './modules/core/translations/en';
import { RUSSIAN_TRANSLATIONS } from './modules/core/translations/ru';

import '@/assets/styles/styles.scss';

const TRANSLATIONS = {
  en: ENGLISH_TRANSLATIONS,
  ru: RUSSIAN_TRANSLATIONS,
};

const i18n = createI18n({
  locale: window.localStorage.Language || 'en',
  fallbackLocale: 'en',
  messages: TRANSLATIONS,
});

const app = createApp(App);

app.use(VueAxios, axios);
app.use(router);
app.use(i18n);
app.mount('#app');
