// eslint-disable-next-line import/prefer-default-export
export const RUSSIAN_TRANSLATIONS = {
  text: {
    // ********* ASIDE MENU *********
    about: 'О нас',
    services: 'Услуги',
    companyName: '&copy; Backer Stream 2022',
    сontacts: 'Контакты',
    blog: 'Блог',

    // SLIDES ON MAIN PAGE
    slideDevelopment: 'ТЕХНИЧЕСКАЯ ЭКСПЕРТИЗА В ДЕЙСТВИИ',
    slideMapping: 'СОЗДАЕМ ГИС-ПРИЛОЖЕНИЯ',
    slideHealthcare: 'ПО ДЛЯ МЕДИЦИНСКОЙ СФЕРЫ',

    // SLIDES ON RIGHT PLACE
    rightPlaceTitle: 'Мы можем помочь со следующими задачами:',
    point1: 'Упростить рутинные бизнес-процессы посредством кастомного ПО',
    point2: 'Подключить подходящего для Ваших задач разработчика',
    point3: 'Сделать мобильное приложение',
    point4: 'Создать веб-приложение',

    // ACHIEVEMENTS PAGE
    achievement1: 'Полное отсутствие текучести кадров с момента основания',
    description1: '',

    achievement2: 'Участие в Open Source проектах (NIST и Django)',
    description2: '',

    achievement3: '100% рейтинг согласно Upwork',
    description3: '',

    achievement4: 'Современные методологии управления проектами',
    description4: '',

    achievement5: '30+ интеграций со сторонними сервисами',
    description5: '',

    achievement6: 'Соответствие международным стандартам (GDRP и HIPAA)',
    description6: '',

    // ********* CONTACTS PAGE *********

    contactsTitle: 'Наш офис',

    submitCVRadio: 'Отправить резюме',
    submitCVTitle: 'Расскажите о себе и приложите любую релевантную информацию о Вашем опыте (примеры кода, резюме).',

    askRadio: 'Обсудить проект',
    askTitle: 'Оставьте актуальную информацию по Вашему проекту для предварительного ознакомления.',

    fieldName: 'Ваше имя',
    fieldEmail: 'E-mail',
    fieldMessageClient: 'О проекте',
    fieldMessageCandidate: 'О Вашем опыте',
    fieldUploadFile: 'Загрузить резюме',
    uploadedFile: 'Загружено',

    errorRequired: 'Пожалуйста, заполните это поле',
    errorEmailFormat: 'Неправильный формат e-mail',

    getNewsCheckbox: 'Получать наши новости',
    privacyPolicyCheckbox: 'Я ознакомился и согласен с <a href="https://backer-stream.com/static/privacy/privacy_policy_ru.pdf" target="_blank">политикой конфиденциальности</a>',

    buttonSend: 'Отправить',

    addressCompany: 'Проспект Михаила Нагибина, 41А',
    addressCompanyDetail: 'Ростов-на-Дону, Россия',

    emailCompany: 'hello@backer-stream.com',

    submitTitle: 'Спасибо!',
    submitMessage: 'Мы скоро Вам ответим',
  },
};
