// eslint-disable-next-line import/prefer-default-export
export const ENGLISH_TRANSLATIONS = {
  text: {

    // ********* ASIDE MENU *********
    about: 'About',
    services: 'Services',
    companyName: '&copy; Backer Stream 2022',
    сontacts: 'Contact Us',
    blog: 'Blog',

    // ********* SLIDES ON MAIN PAGE *********
    slideDevelopment: 'TO A GREAT MIND NOTHING IS LITTLE',
    slideMapping: 'CREATION OF GIS AND MAPPING BASED APPS',
    slideHealthcare: 'CREATION OF HEALTHCARE APPS',

    // ********* SLIDES ON RIGHT PLACE *********
    rightPlaceTitle: 'You came to the right place If you want to:',
    point1: 'Streamline routine processes by digital transformation of business',
    point2: 'Strengthen the team by a dedicated resource',
    point3: 'Build a mobile application',
    point4: 'Create the web product',

    // ********* ACHIEVEMENTS PAGE *********
    achievement1: 'No turnover of staff',
    description1: 'Starting from the company foundation',

    achievement2: 'Open source contributions\n\r(Django, NIST)',
    description2: '',

    achievement3: '100% Job Success Score on Upwork',
    description3: '',

    achievement4: 'LEAN methodology',
    description4: '',

    achievement5: '30+ integrations made',
    description5: '',

    achievement6: 'GDRP and HIPAA compliance',
    description6: '',

    // ********* CONTACTS PAGE *********

    contactsTitle: 'Easy to find',

    submitCVRadio: 'Submit a CV',
    submitCVTitle: 'Please fill the information about yourself and upload your CV.',

    askRadio: 'Discuss the project',
    askTitle: 'Let\'s connect and see how Backer Stream can help you?',

    fieldName: 'Name',
    fieldEmail: 'E-mail',
    fieldMessageClient: 'Tell us about your project',
    fieldMessageCandidate: 'Tell us about about your experience',
    fieldUploadFile: 'Upload CV',
    uploadedFile: 'Uploaded',

    errorRequired: 'Please fill out this field',
    errorEmailFormat: 'Wrong email format',

    getNewsCheckbox: 'Get our updates',
    privacyPolicyCheckbox: 'I have read and agree to the <a href="https://backer-stream.com/static/privacy/privacy_policy_en.pdf" target="_blank">privacy policy</a>',

    buttonSend: 'Send',

    addressCompany: '41A, Mikhaila Nagibina ave.',
    addressCompanyDetail: 'Rostov-on-Don, Russia',

    emailCompany: 'hello@backer-stream.com',

    submitTitle: 'Thanks!',
    submitMessage: 'We’ll get back to you soon',
  },
};
