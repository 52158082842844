import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('./modules/home/home.vue'),
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('./modules/home/components/blog.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
