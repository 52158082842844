// eslint-disable-next-line no-unused-vars,no-useless-escape
export const EMAIL_CHECK_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// ************************** MAIN PAGE **************************
export const SLIDES_MAIN = [
  {
    id: 1, title: 'slideDevelopment', image: 'development-full.svg', animation: 'development.webm',
  },
  {
    id: 2, title: 'slideMapping', image: 'mapping-full.svg', animation: 'mapping.webm',
  },
  {
    id: 3, title: 'slideHealthcare', image: 'healthcare-full.svg', animation: 'healthcare.webm',
  },
];

export const SLIDES_PLACE = [
  { id: 1, title: 'point1', image: 'point-1.svg' },
  { id: 2, title: 'point2', image: 'point-2.svg' },
  { id: 3, title: 'point3', image: 'point-3.svg' },
  { id: 4, title: 'point4', image: 'point-4.svg' },
];

export const ACHIEVEMENTS_ITEMS = [
  {
    id: 1,
    title: 'achievement1',
    description: 'description1',
    image: 'achieved-1.svg',
  },
  {
    id: 2,
    title: 'achievement2',
    description: 'description2',
    image: 'achieved-2.svg',
  },
  {
    id: 3,
    title: 'achievement3',
    description: 'description3',
    image: 'achieved-3.svg',
  },
  {
    id: 4,
    title: 'achievement4',
    description: 'description4',
    image: 'achieved-4.svg',
  },
  {
    id: 5,
    title: 'achievement5',
    description: 'description5',
    image: 'achieved-5.svg',
  },
  {
    id: 6,
    title: 'achievement6',
    description: 'description6',
    image: 'achieved-6.svg',
  },
];

export const LEFT_ASIDE_PANEL = {

  color: 'blue',

  itemsHeader: [
    {
      id: 1, title: 'Burger', image: 'burger.svg', name: 'Home',
    },
  ],

  itemsBurger: [
    {
      id: 1, title: 'about', url: '/', name: 'Home',
    },
    {
      id: 2, title: 'blog', url: '/blog', name: 'Home',
    },
  ],

  itemsMenu: [

    {
      id: 1, title: 'сontacts', url: '/', slide: 3, mobileSlide: 5, name: 'Home',
    },
    {
      id: 2, title: 'about', url: '/', slide: 2, mobileSlide: 2, name: 'Home',
    },
    {
      id: 3, title: 'services', url: '/', slide: 1, mobileSlide: 1, name: 'Home',
    },
    {
      id: 4, title: 'blog', url: '/blog', name: 'Blog',
    },
  ],

  itemsFooter: [
    {
      id: 1, title: 'Email', image: 'email.svg', url: '/', slide: 3, name: 'Home',
    },
  ],
};

export const RIGHT_ASIDE_PANEL = {
  position: 'right-side',
  color: 'blue-dark',
  itemsHeader: [
    {
      id: 1, title: 'Backer', image: 'backer-stream-icon.svg', url: '/',
    },
  ],

  itemsMenu: [
    { id: 1, title: 'companyName', url: '' },
  ],

  itemsFooter: [
    {
      id: 1, title: 'Email', image: 'facebook-icon.svg', url: 'https://www.facebook.com/BackerStream/',
    },
    {
      id: 2, title: 'Email', image: 'linkedin-icon.svg', url: 'https://www.linkedin.com/company/backer-stream/',
    },
    {
      id: 3, title: 'Email', image: 'insta-icon.svg', url: 'https://www.instagram.com/backer_stream/',
    },
  ],
};
