<template>
    <div class="main">

        <div class="main__aside-block">
            <aside-menu :options="optionsAsidePanel"></aside-menu>
        </div>

        <div class="main__content-block">
            <router-view/>
        </div>

    </div>
</template>

<style lang="scss">
    @font-face {
        font-family: 'Geometria-Heavy';
        src: url('./assets/fonts/geometria/Geometria-Heavy.woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Geometria-bold';
        src: url('./assets/fonts/geometria/Geometria-Bold.woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Geometria';
        src: url('./assets/fonts/geometria/Geometria-Medium.woff');
        font-weight: normal;
        font-style: normal;
    }

    body {
        font-family: 'Geometria-Heavy', Arial, serif;
    }
</style>

<script>
import { Aside } from '@/shared-components';
import { LEFT_ASIDE_PANEL } from './modules/core/utils/constants';

export default {
  name: 'App',

  components: {
    'aside-menu': Aside,
  },

  data() {
    return {
      optionsAsidePanel: LEFT_ASIDE_PANEL,
    };
  },
};
</script>
