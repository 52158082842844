<template>
    <div ref='googleMap' class='google-map'></div>
</template>

<style lang="scss">
    .google-map {
        position: absolute;
        height: 100vh !important;
        width: 100% !important;
        z-index: 1;

        @media (max-width: 576px)
        {
            display: none;
        }
    }
</style>

<script>
export default {
  name: 'google-map',
  props: {
    locations: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      // eslint-disable-next-line no-undef
      bounds: new google.maps.LatLngBounds(), // auto scale
      mapOptions: {
        center: { lat: 47.260602, lng: 39.715949 },
        zoomControlOptions: {
          // eslint-disable-next-line no-undef
          position: google.maps.ControlPosition.LEFT_CENTER,
        },
        zoomControl: true,
        zoom: 17,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        gestureHandling: 'cooperative',
      },
    };
  },

  mounted() {
    this.initMap();
  },

  methods: {
    initMap() {
      const { locations } = this.locations;
      // create map
      // eslint-disable-next-line no-undef
      const map = new google.maps.Map(this.$refs.googleMap, {
        ...this.mapOptions,
      });

      // create Markers
      // eslint-disable-next-line no-unused-vars
      const markers = locations.map((location) => {
        // set locations for auto zoom map
        // eslint-disable-next-line no-undef
        const setLocations = new google.maps.LatLng(location.lat, location.lng);
        this.bounds.extend(setLocations);

        // eslint-disable-next-line no-undef
        return new google.maps.Marker({
          position: location,
          map,
          label: location.name_point,
          // eslint-disable-next-line no-undef,global-require
          icon: new google.maps.MarkerImage(require('@/assets/images/find-form/geotag.svg'), null, null, null, new google.maps.Size(100, 100)),
          title: `${location.title} ${location.name_point}`,
        });
      });

      // auto scale
      // map.fitBounds(this.bounds);
    },
  },
};
</script>
